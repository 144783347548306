<template>
    <div id="handleMenu">
        <div class="menu-box">
            <div
                    class="menu-item"
                    @click="handleMenu(item)"
                    v-for="(item, index) in menuList"
                    :key="index"
            >
                <img :src="item['pic']" alt/>
                <div>
                    <p class="title">{{item.title}}</p>
                    <p class="inner">{{item.inner}}</p>
                </div>
            </div>
        </div>

        <van-overlay :show="isAddShow" @click="isAddShow = false">
            <div class="add-wrapper" @click.stop>
                <div class="add-title">请选择添加方式</div>
                <div
                        class="add-item"
                        @click="$router.push({path: '/commodityOperate', query: {type: 'add'}})"
                >
                    <span>手动添加</span>
                    <img src="@images/arrow-nav.png" alt/>
                </div>
                <!-- TODO 需要在完成从商品库导入功能后开放 -->
                <div class="add-item" @click="$router.push({path:'/importProduct'})">
                    <span>从商品库导入</span>
                    <img src="@images/arrow-nav.png" alt/>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {listNum} from "../api/requist";

    export default {
        components: {},
        props: [],
        data() {
            return {
                refuseNum: 0,
                isAddShow: false,
                menuList: [
                    {
                        type: 1,
                        pic: require("@images/handle-1.png"),
                        title: "添加商品",
                        inner: "通过手动添加、一键导入等方式添加商品",
                    },
                    {
                        type: 2,
                        pic: require("@images/handle-2.png"),
                        title: "售罄商品（共 0 件）",
                        inner: "点击进入恢复可供应的已售罄商品",
                    },
                    {
                        type: 3,
                        pic: require("@images/handle-3.png"),
                        title: "下架商品（共 0 件）",
                        inner: "删除或恢复上架已下架的商品",
                    },
                    {
                        type: 4,
                        pic: require("@images/handle-4.png"),
                        title: "审核查询",
                        inner: "商品审核进度查询，重新编辑审核未通过商品",
                    },
                ],
            };
        },
        created() {
            this.getInfo();
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            async getInfo() {
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                let res = await listNum(formData);
                if (res.code === 200) {
                    this.menuList[1].title = `售罄商品（共 ${res.data.soldOutCount} 件）`;
                    this.menuList[2].title = `下架商品（共 ${res.data.OffShelfCount} 件）`
                }
            },
            handleMenu(item) {
                if (item.type === 1) {
                    this.isAddShow = true;
                }

                if (item.type === 2) {
                    this.$router.push({
                        path: "/sellOut",
                        query: {type: "sellOut"},
                    });
                }
                if (item.type === 3) {
                    this.$router.push({
                        path: "/sellOut",
                        query: {type: "shelf"},
                    });
                }

                if (item.type === 4) {
                    this.$router.push({
                        path: "/examine",
                    });
                }
            },
        },
    };
</script>
<style lang="scss">
    #handleMenu {
        min-height: 100vh;
        background: rgba(245, 247, 250, 1);

        .menu-box {
            padding: 17px 14px 0;
            box-sizing: border-box;

            .menu-item {
                border-radius: 4px;
                background-color: #fff;
                margin-bottom: 17px;
                padding: 14px 15px;
                box-sizing: border-box;
                display: flex;
                align-items: center;

                img {
                    margin-right: 17px;
                    height: 60px;
                    width: 60px;
                }

                .title {
                    text-align: left;
                    font-size: 16px;
                    margin-bottom: 7px;
                    color: rgba(48, 49, 51, 1);
                }

                .inner {
                    text-align: left;
                    font-size: 12px;
                    color: rgba(48, 49, 51, 1);
                }
            }
        }

        .van-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .add-wrapper {
            border-radius: 6px;
            width: 313px;
            background-color: #fff;

            .add-title {
                height: 45px;
                line-height: 45px;
                padding-left: 21px;
                box-sizing: border-box;
                color: rgba(48, 49, 51, 1);
                font-size: 14px;
                text-align: left;
            }

            .add-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 0 13px 0 21px;
                /*TODO 需要完成从商品库导入商品功能后开放*/
                /*&:nth-child(2) {*/
                /*  border: 1px solid rgba(242, 246, 252, 1);*/
                /*}*/

                span {
                    color: rgba(48, 49, 51, 1);
                    font-size: 16px;
                }

                img {
                    height: 12px;
                    width: 6px;
                }
            }
        }
    }
</style>
